























import { defineComponent, PropType, reactive, ref } from "@vue/composition-api";
import {
  RasmusUserForm,
  RasmusEditUserParams,
  RasmusEditUserKidParams,
  ApiErrorType,
} from "@/features/rasmus-kids-club/shared";

import {
  rasmusRequest,
  apiDateFormat,
  redirectToPath,
  getTranslatedErrorForMessage,
} from "@/features/rasmus-kids-club/shared/helpers";

import { RasmusEditUserApiData, RasmusEditUserSettings } from ".";

export default defineComponent({
  name: "RasmusEditUser",
  components: {
    RasmusUserForm,
  },
  props: {
    settings: {
      type: Object as PropType<RasmusEditUserSettings>,
      default: undefined,
    },
  },
  setup(props) {
    const settings: RasmusEditUserSettings = props.settings;
    const userData = settings.user;
    const newsletterLink = settings.newsletterLink;
    const privacyLink = settings.privacyLink;
    const backLink = settings.backLink;

    let errors: ApiErrorType = reactive({
      messages: undefined,
    });

    let submitting = ref(false);
    let deactivateMessage = ref("");

    const apiFormatKids = (kids: RasmusEditUserKidParams[]) =>
      kids?.map((kid) => ({
        action: kid.action ?? "UPDATE",
        uid: kid.uid,
        gender: kid.gender,
        birthday: apiDateFormat(kid.birthday),
        firstName: kid.firstName,
        lastName: kid.lastName,
      }));

    const generateApiData = (
      formData: RasmusEditUserParams
    ): RasmusEditUserApiData => {
      return {
        action: "UPDATE",
        data: {
          uid: formData.uid,
          email: formData?.email,
          address: formData?.address,
          streetNumber: formData?.streetNumber,
          city: formData?.city,
          zip: formData?.zip,
          newsletter: formData?.newsletter,
          customerNumber: formData?.customerNumber,
          firstNameParentOne: formData?.firstNameParentOne,
          lastNameParentOne: formData?.lastNameParentOne,
          firstNameParentTwo: formData?.firstNameParentTwo,
          lastNameParentTwo: formData?.lastNameParentTwo,
          token: settings.token,
          kids: apiFormatKids(formData.kids),
        },
      };
    };

    const submitApiData = async (formData: RasmusEditUserParams) => {
      submitting.value = true;
      const apiData = generateApiData(formData);
      const response = await rasmusRequest<RasmusEditUserApiData>(apiData);
      submitting.value = false;

      if (response.success) {
        redirectToPath(response.data?.redirectUrl);
      } else {
        const translatedMessage = getTranslatedErrorForMessage(
          response.message
        );

        errors.messages = translatedMessage;
      }
    };

    const deactivateAccount = async () => {
      submitting.value = true;
      const response = await rasmusRequest({
        action: "DEACTIVATE",
        data: {
          token: settings.token,
          uid: userData.uid,
        },
      });
      submitting.value = false;

      if (response.success) {
        // Both ways can be used for now it is the redirect
        deactivateMessage.value =
          "Wir haben Ihnen eine E-Mail zur Bestätigung der Löschung ihres Accounts gesendet.";
        redirectToPath(response.data?.redirectUrl);
      } else {
        const translatedMessage = getTranslatedErrorForMessage(
          response.message
        );

        errors.messages = translatedMessage;
      }
    };

    return {
      data: props.settings,
      userData,
      submitting,
      deactivateMessage,
      errors,
      newsletterLink,
      privacyLink,
      backLink,
      submitApiData,
      deactivateAccount,
    };
  },
});
