/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";

import {
  RasmusEditUser,
  RasmusEditUserConfig,
} from "@/features/rasmus-kids-club/users/edit";

export const initRasmusEditUser = () => {
  const el = document.getElementById("rasmus-edit-user");

  if (!el) {
    return;
  }

  try {
    const dataSettings = el.getAttribute("data-settings");

    if (!dataSettings) {
      return;
    }

    const parsedSettings: RasmusEditUserConfig = JSON.parse(dataSettings);

    new Vue({
      el,
      render: (h) =>
        h(RasmusEditUser, {
          props: {
            settings: parsedSettings,
          },
        }),
    });
  } catch (err) {
    console.error('Failed to load module "RasmusEditUser"');
  }
};

initRasmusEditUser();
